import { ROUTES } from "@src/constants"

export const AboutTheCiu = [
  {
    title: "Giới thiệu",
    key: "introduction",
    href: ROUTES.INTRODUCE
  },
  {
    title: "Tuyển dụng & Việc làm",
    key: "recruitment_and_careers",
    href: ROUTES.RECRUITMENT
  },
  {
    title: "Blog",
    key: "blog",
    href: ROUTES.BLOG
  },
  {
    title: "FAQ",
    key: "FAQ",
    href: ROUTES.FAQ
  }
]

export const Policy = [
  {
    title: "Chính sách kiểm hàng & bảo hành",
    key: "inspection_and_warranty_policy",
    href: `${ROUTES.ABOUT_US}?tab=policy`
  },
  {
    title: "Chính sách bảo mật",
    key: "privacy_policy",
    href: ROUTES.PRIVACY_POLICY
  },
  {
    title: "Chính sách Membership",
    key: "membership_policy",
    href: ROUTES.MEMBERSHIP
  }
]