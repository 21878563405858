"use client";

import { useMemo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextImage } from "@src/components";
import Logo from "@src/components/logo";
import SocialGroup from "./social-group";
import CollapseFooterItem from "./collapse-footer-item";
import { AboutTheCiu, Policy } from "./footer-constants";
import Link from "next/link";
import { useTranslation } from "react-i18next";
const Footer = ({
  branches = []
}) => {
  const {
    t
  } = useTranslation();
  return <Box component="footer" sx={{
    backgroundColor: "transparent",
    zIndex: theme => theme.zIndex.appBar,
    padding: "24px 16px"
  }} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="footer-mobile.tsx">
      <Stack direction={"column"} height={"100%"} pb={3} data-sentry-element="Stack" data-sentry-source-file="footer-mobile.tsx">
        <CollapseFooterItem title="THÔNG TIN LIÊN HỆ" items={[{
        title: "Email: cskh@theciu.vn",
        href: "#"
      }, {
        title: "Hotline: 0775 665 912",
        href: "#"
      }]} data-sentry-element="CollapseFooterItem" data-sentry-source-file="footer-mobile.tsx" />
        <CollapseFooterItem title={t('about_theciu')} items={AboutTheCiu} data-sentry-element="CollapseFooterItem" data-sentry-source-file="footer-mobile.tsx" />
        <CollapseFooterItem title={t('customer_policy')} items={Policy} data-sentry-element="CollapseFooterItem" data-sentry-source-file="footer-mobile.tsx" />
        <CollapseFooterItem title={t('store_system')} items={branches} data-sentry-element="CollapseFooterItem" data-sentry-source-file="footer-mobile.tsx" />
        <SocialGroup data-sentry-element="SocialGroup" data-sentry-source-file="footer-mobile.tsx" />
      </Stack>
      <Stack direction={"column"} height={"100%"} pt={3} sx={{
      borderTop: "1px solid #0000004c"
    }} data-sentry-element="Stack" data-sentry-source-file="footer-mobile.tsx">
        <Logo data-sentry-element="Logo" data-sentry-source-file="footer-mobile.tsx" />
        <Stack direction={"column"} spacing={1} mt={3} data-sentry-element="Stack" data-sentry-source-file="footer-mobile.tsx">
          <Typography fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="footer-mobile.tsx">
            {t('company_name')}
          </Typography>
          <Typography data-sentry-element="Typography" data-sentry-source-file="footer-mobile.tsx">
            {" "}
            {t('head_address')}
          </Typography>
          <Typography data-sentry-element="Typography" data-sentry-source-file="footer-mobile.tsx">Mã số doanh nghiệp: 0317478388</Typography>
          <Typography data-sentry-element="Typography" data-sentry-source-file="footer-mobile.tsx">Ngày hoạt động: 16/09/2022</Typography>
          <Box data-sentry-element="Box" data-sentry-source-file="footer-mobile.tsx">
            <Link href="http://online.gov.vn/Home/WebDetails/106122" data-sentry-element="Link" data-sentry-source-file="footer-mobile.tsx">
              <NextImage src="/assets/images/bo-cong-thuong.png" alt="Bộ công thương" sx={{
              width: "auto",
              height: {
                xs: "55px",
                md: "64px"
              },
              "& img": {
                position: "relative !important",
                width: "auto !important",
                maxWidth: "200px !important"
              }
            }} data-sentry-element="NextImage" data-sentry-source-file="footer-mobile.tsx" />
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Box>;
};
export default Footer;