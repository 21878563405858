"use client";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextImage } from "@src/components";
import Logo from "@src/components/logo";
import Link from "next/link";
import SocialGroup from "./social-group";
import { AboutTheCiu, Policy } from "./footer-constants";
import WrapperLinkHover from "@src/components/WrapperLinkHover";
import { useTranslation } from "react-i18next";
const Footer = ({
  branches = []
}) => {
  const {
    t
  } = useTranslation();
  return <Box sx={{
    backgroundColor: "transparent",
    zIndex: theme => theme.zIndex.appBar,
    pb: "20px",
    pt: "46px",
    paddingX: "44px",
    maxWidth: "1440px",
    margin: "auto"
  }} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="footer-desktop.tsx">
      <Grid container spacing={{
      xs: 3,
      md: 0
    }} direction={{
      xs: "column-reverse",
      md: "row"
    }} data-sentry-element="Grid" data-sentry-source-file="footer-desktop.tsx">
        <Grid item xs={5} data-sentry-element="Grid" data-sentry-source-file="footer-desktop.tsx">
          <Stack direction={"column"} height={"100%"} spacing={3} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
            <Logo data-sentry-element="Logo" data-sentry-source-file="footer-desktop.tsx" />
            <Stack direction={"column"} spacing={1} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
              <Typography data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">{t("head_address")}</Typography>
              <Typography data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
                <strong>Email:</strong>
                <a href="mailto:cskh@theciu.vn" style={{
                color: "black",
                textDecoration: "none",
                paddingLeft: "4px"
              }}>
                  cskh@theciu.vn
                </a>
              </Typography>
              <Typography data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
                <strong>Hotline:</strong>
                <a href="tel:0775665912" style={{
                color: "black",
                textDecoration: "none",
                paddingLeft: "4px"
              }}>
                  0775665912
                </a>
              </Typography>
              <SocialGroup data-sentry-element="SocialGroup" data-sentry-source-file="footer-desktop.tsx" />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={7} data-sentry-element="Grid" data-sentry-source-file="footer-desktop.tsx">
          <Stack justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
            <Stack direction={"column"} height={"100%"} spacing={3} sx={{
            "& a": {
              color: "black",
              textDecoration: "none",
              "&:hover": {
                fontWeight: 700
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
              <Typography variant="body1" fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
                {t("about_theciu")}
              </Typography>
              <Stack direction={"column"} spacing={1} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
                {AboutTheCiu.map((item, index) => <WrapperLinkHover key={index} justifyContent="flex-start" title={t(item.key) ?? item.title}>
                    <Link href={item.href}>{t(item.key) ?? item.title}</Link>
                  </WrapperLinkHover>)}
              </Stack>
            </Stack>
            <Stack direction={"column"} height={"100%"} spacing={3} sx={{
            "& a": {
              color: "black",
              textDecoration: "none",
              "&:hover": {
                fontWeight: 700
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
              <Typography variant="body1" fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
                {t("customer_policy")}
              </Typography>
              <Stack direction={"column"} spacing={1} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
                {Policy.map((item, index) => <WrapperLinkHover key={index} justifyContent="flex-start" title={t(item.key) ?? item.title}>
                    <Link href={item.href}>{t(item.key) ?? item.title}</Link>
                  </WrapperLinkHover>)}
              </Stack>
            </Stack>
            <Stack direction={"column"} height={"100%"} spacing={3} sx={{
            "& a": {
              color: "black",
              textDecoration: "none",
              "&:hover": {
                fontWeight: 700
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
              <Typography variant="body1" fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
                {t("branch_system")}
              </Typography>
              <Stack direction={"column"} spacing={1} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
                {branches?.length > 0 && branches.map((item, index) => <WrapperLinkHover key={index} justifyContent="flex-start" title={item.title}>
                      <Link href={item.href}>{item.title}</Link>
                    </WrapperLinkHover>)}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{
      borderTop: "1px solid #0000004c"
    }} alignItems="center" spacing="16px" mt="60px" pt={1} data-sentry-element="Stack" data-sentry-source-file="footer-desktop.tsx">
        <Link href="http://online.gov.vn/Home/WebDetails/106122" data-sentry-element="Link" data-sentry-source-file="footer-desktop.tsx">
          <NextImage src="/assets/images/bo-cong-thuong.png" alt="Bộ công thương" sx={{
          width: "auto",
          height: {
            xs: "55px",
            md: "64px"
          },
          maxWidth: "200px",
          "& img": {
            position: "relative !important",
            width: "auto !important",
            maxWidth: "200px !important"
          }
        }} data-sentry-element="NextImage" data-sentry-source-file="footer-desktop.tsx" />
        </Link>
        <Box data-sentry-element="Box" data-sentry-source-file="footer-desktop.tsx">
          <Typography variant="body2" fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
            {t("company_name")}
          </Typography>
          <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="footer-desktop.tsx">
            {t("business_registration_number")}
          </Typography>
        </Box>
      </Stack>
    </Box>;
};
export default Footer;